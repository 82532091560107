/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-10-14 17:37:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialogClass.js
 */


/* eslint-disable */
class searchFrom {
  validateTemp = (rule, value, callback) => {
    // if(value) {
    //   var temp = value.replace(/\s/g, "");//去除空格
    // } else {
    // }
    if(value) {
      // var temp = value.replace(/\s/g, "");//去除空格
      var temp = value
    } 
    // let reg = /^(?:[1-9]?\d|100)$/
    // let reg = /^(([^0][0-9]+|0)\.([0-9]{1,2}))$/
    let reg = /^[0-9]+([.]{1}[0-9]+){0,1}$/
    if(!value) {
      callback([new Error('请输入报警体温')]);
    } else {
      if(!reg.test(temp) && !reg.test(temp)) {
        callback([new Error('报警体温输入不合法')])
      } else {
        callback()
      }
    }
  }
  validateInnerAddr = (rule,value,callback)=> {
    console.log("value===>内网地址",value)
    if(value) {
      // var temp = value.replace(/\s/g, "");//去除空格
      var temp = value
    } 
    let reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\:([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5])$/;
    if(!value) {
      callback([new Error('请输入内网地址')]);
    } else {
      if(!reg.test(temp) && !reg.test(temp)) {
        callback([new Error('内网地址不合法')])
      } else {
        callback()
      }
    }
  }
  constructor(type) {
    if (type === 'form') {
      this.venueName = ''
      this.deviceType = '1'
      this.venueId = ''
      this.sportsProjectCode= ''
      this.sportsProjectName = ''
      this.deviceCode = ''
      this.deviceName = ''
      this.deviceUnit=''
      this.location = ''
      this.temperatureWarn = ''
      this.password = ''
      this.innerAddr = ''
      this.direction = ''
    } else if (type === 'rule') {
      this.venueId = [{ required: true, message: '请选择场馆/中心',  trigger: 'blur' }]
      this.sportsProjectCode = [{ required: true, message: '请选择运动项目',  trigger: ['change', 'blur'] }]
      this.sportsProjectName = [{ required: true, message: '请选择运动项目',  trigger: ['change', 'blur'] }]
      this.deviceCode = [{ required: true, message: '请输入设备ID', trigger: 'blur' }]
      this.deviceName = [{ required: true, message: '请输入设备名称', trigger: 'blur' }]
      this.deviceUnit = [{ required: true, message: '请输入设备型号', trigger: 'blur' }]
      this.location = [{ required: true, message: '请输入安装地点', trigger: 'blur' }]
      this.temperatureWarn = [{ required: true, message: '请输入合法报警体温 ℃', trigger:  ['change', 'blur'], validator: this.validateTemp, }]
      // this.temperatureWarn = [{ required: true, message: '请输入合法报警体温 ℃', trigger:  ['change', 'blur'], }]
      this.password = [{ required: true, message: '请输入闸机密码', trigger: 'blur' }]
      this.innerAddr = [{ required: true, message: '请输入内网地址', trigger: 'blur', validator: this.validateInnerAddr, }]
      this.direction = [{ required: true, message: '请选择进出方向',  trigger: ['change', 'blur'] }]
    }
  }
}
export default searchFrom
